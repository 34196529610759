import React from "react";

import Intro from "./components/intro";
import Skills from "./components/skills";
import Portfolio from "./components/portfolio";
import Blogs from "./components/blogs";

import Footer from "./components/footer";

import "./app.scss";
import Contactgrid from "./components/footer/social-icon/contactGrid/Contactgrid";

const App = () => {
  return (
    <div>
      <Intro />
      <Skills />
      <Portfolio />
      <Blogs />
      {/* <Contact /> */}
      <Footer />
    </div>
  );
};

export default App;
