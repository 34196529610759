import React from "react";

import Section from "../shared/section";

import "react-circular-progressbar/dist/styles.css";

import "./style.scss";
import SkillsCard from "./SkillsCard";

const Skills = () => {
  return (
    <Section background="dark" id="skills" title={"Skills"}>
      <div className="skills-content-wrapper">
        <SkillsCard />
      </div>
    </Section>
  );
};

export default Skills;
