import React from "react";
import { AiOutlineCloudDownload } from "react-icons/ai";

import logo from "../../../images/logo.png";
import CallToAction from "../../shared/CallToAction";
import { scrollToSection } from "../../utils/helpers";
import resume from "../../../resume/resume.pdf";

import "./style.scss";

const Navigation = () => {
  const newTab = (url) => window.open(url);
  return (
    <div className="top-navigation-bar">
      <div className="app-logo">
        <img src={logo} alt="pcatjee" />
      </div>
      <div className="navigation">
        <span
          className="navigation-item"
          onClick={() => scrollToSection("skills")}
        >
          Skills
        </span>
        <span
          className="navigation-item"
          onClick={() => scrollToSection("portfolio")}
        >
          Portfolio
        </span>
        <span
          className="navigation-item"
          onClick={() => scrollToSection("blogs")}
        >
          Blogs & Articles
        </span>
        <span
          className="navigation-item"
          onClick={() => scrollToSection("contact")}
        >
          Contact
        </span>
        <CallToAction
          text="Download CV"
          action={() => newTab(resume)}
          icon={<AiOutlineCloudDownload />}
        />
      </div>
    </div>
  );
};

export default Navigation;
