import React from "react";

import phoneIcon from "../../../../images/socialicons/phone-icon.png";
import linkinIcon from "../../../../images/socialicons/linkedin-icon.png";
import emailIcon from "../../../../images/socialicons/email-icon.png";
import locationIcon from "../../../../images/socialicons/gps-icon.png";

import "./style.scss";

const Contactgrid = () => {
  return (
    <div className="contacts-showcase" id="contact">
      <div className="indi-detail">
        <div className="meta-content">
          <img src={phoneIcon} alt="" />
          <h3>Phone</h3>
          <p>+91-9891977489</p>
        </div>
      </div>
      <div className="indi-detail">
        <div className="meta-content">
          <img src={linkinIcon} alt="" />
          <h3>LinkedIn</h3>
          <p>piyushchatterjee</p>
        </div>
      </div>
      <div className="indi-detail">
        <div className="meta-content">
          <img src={emailIcon} alt="" />
          <h3>Email</h3>
          <p>pcatjee@gmail.com</p>
        </div>
      </div>
      <div className="indi-detail">
        <div className="meta-content">
          <img src={locationIcon} alt="" />
          <h3>Location</h3>
          <p>Noida IN</p>
        </div>
      </div>
    </div>
  );
};

export default Contactgrid;
