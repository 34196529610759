import React from "react";
import "react-circular-progressbar/dist/styles.css";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { skillSets } from "./skillsets";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import "./SkillsCard.css";

const SkillsCard = () => {
  return (
    <div className="individual-skills">
      {skillSets.map((curUser, index) => {
        return (
          <div
            className="individual-skills-children"
            id={index}
            data-tooltip-content={curUser.skill_Name}
          >
            <CircularProgressbarWithChildren
              value={curUser.value_percentage}
              styles={buildStyles({
                textColor: "",
                pathColor: curUser.path_Color,
                trailColor: curUser.trail_Color,
              })}
            >
              <img
                style={{ width: 80, marginTop: -5 }}
                src={curUser.image}
                alt={curUser.skill_Name}
              />
            </CircularProgressbarWithChildren>
            <Tooltip
              anchorId={index}
              style={{ backgroundColor: "#FED22B", color: "#222" }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SkillsCard;
