export const skillSets = [
  {
    value_percentage: 80,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/react.svg",
    skill_Name: "React",
  },
  {
    value_percentage: 70,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/redux.svg",
    skill_Name: "Redux",
  },
  {
    value_percentage: 70,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/nextjs1.svg",
    skill_Name: "NextJS",
  },
  {
    value_percentage: 90,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/javascript.svg",
    skill_Name: "Javascript",
  },
  {
    value_percentage: 60,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/typescript.svg",
    skill_Name: "Typescript",
  },
  {
    value_percentage: 90,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/html.svg",
    skill_Name: "HTML",
  },
  {
    value_percentage: 85,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/api1.svg",
    skill_Name: "API Integration",
  },
  {
    value_percentage: 85,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/sql.svg",
    skill_Name: "SQL",
  },
  {
    value_percentage: 70,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/firebase.svg",
    skill_Name: "Firebase",
  },
  {
    value_percentage: 85,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/figma.svg",
    skill_Name: "Figma",
  },
  {
    value_percentage: 95,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/css.svg",
    skill_Name: "CSS",
  },
  {
    value_percentage: 75,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/scss.svg",
    skill_Name: "SCSS",
  },
  {
    value_percentage: 80,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/bulma1.svg",
    skill_Name: "Bulma",
  },
  {
    value_percentage: 80,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/materialui1.svg",
    skill_Name: "Material UI",
  },
  {
    value_percentage: 80,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/tailwind.svg",
    skill_Name: "Tailwind",
  },
  {
    value_percentage: 85,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/git.svg",
    skill_Name: "Git",
  },
  {
    value_percentage: 90,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/photoshop.svg",
    skill_Name: "Photoshop",
  },
  {
    value_percentage: 80,
    path_Color: "#03A0C6",
    trail_Color: "#D6D6D6",
    image: "images/skills/bootstrap.svg",
    skill_Name: "Bootstrap",
  },
];
