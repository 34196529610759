import React, { useState } from "react";

import Section from "../shared/section";
import Filters from "./filters";
import Showcase from "./showcase";

import "./style.scss";

const projectsData = [
  {
    id: 1,
    name: "Hulu Clone",
    tags: ["web-app", "mobile-app"],
    media: {
      thumbnail: require("../../images/portfolio/hulu-clone.png"),
    },
    githublink: "https://github.com/pcatjee/hulu-clone",
  },
  {
    id: 2,
    name: "Google Clone",
    tags: ["web-page"],
    media: {
      thumbnail: require("../../images/portfolio/google-clone.png"),
    },
    githublink: "https://github.com/pcatjee/google-clone",
  },
  {
    id: 3,
    name: "Netflix Clone",
    tags: ["web-page"],
    media: {
      thumbnail: require("../../images/portfolio/netflix-clone.png"),
    },
    githublink: "https://github.com/pcatjee/netflix-clone",
  },
  {
    id: 4,
    name: "Add to cart",
    tags: ["web-app", "mobile-app"],
    media: {
      thumbnail: require("../../images/portfolio/Add-to-cart.png"),
    },
    githublink: "https://github.com/pcatjee/simple-add-to-cart",
  },
  {
    id: 5,
    name: "Hotel Booking",
    tags: ["web-app", "mobile-app"],
    media: {
      thumbnail: require("../../images/portfolio/Hotel-booking.png"),
    },
    githublink: "https://github.com/pcatjee/hotel-booking",
  },
];

const Portfolio = () => {
  const [projects, setProjects] = useState(projectsData);
  const [transition, setTransition] = useState(false);

  const filterProjects = (tag) => {
    setTransition("zoomout");

    setTimeout(() => {
      if (tag !== "all") {
        const filteredProjects = projectsData.filter((f) =>
          f.tags.includes(tag)
        );
        setProjects(filteredProjects);
      } else {
        setProjects(projectsData);
      }
      setTransition("zoomin");
    }, 200);

    setTimeout(() => {
      setTransition(false);
    }, 600);
  };

  return (
    <Section id="portfolio" title="Check My Portfolio" background="light">
      <div className="portfolio-content-wrapper">
        {/* <Filters filterProjects={(tag) => filterProjects(tag)} /> */}
        <Showcase data={projects} transition={transition} />
      </div>
    </Section>
  );
};

export default Portfolio;
