import React from "react";
// import { BsAwardFill } from "react-icons/bs";
// import { FaUser } from "react-icons/fa";
import pcatjee from "../../../images/pcatjee.png";
import CallToAction from "../../shared/CallToAction";
import Typewriter from "typewriter-effect";
import { GrLinkedin } from "react-icons/gr";
import { BsGithub } from "react-icons/bs";
import "./style.scss";

const IntroContent = () => {
  const newTab = (url) => window.open(url);
  return (
    <div className="intro-content">
      <div className="layout">
        <div className="left-col">
          <div className="title">
            <div className="small-text">
              Hi, I'm Piyush
              <span className="icon">👋</span>
            </div>

            <div className="text-animation">
              <span className="smaller-text">And I am</span>
              <span className="moving-text">
                <Typewriter
                  options={{
                    strings: [
                      "Frontend Enthusiast",
                      "Open Source Contributor",
                      "Dedicated Programmer",
                      "Team Person",
                      "React Developer",
                    ],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </span>
            </div>
          </div>

          <p>
            A passionate Frontend Software Developer 🚀 having a special
            interest in Frontend technologies and experience of building Web
            applications with JavaScript / Reactjs and some other cool libraries
            and frameworks.
          </p>
          <div className="social-buttons">
            <CallToAction
              text="View on Github"
              action={() => newTab("https://github.com/pcatjee")}
              icon={<BsGithub />}
            />
            <CallToAction
              text="View on LinkedIn"
              action={() =>
                newTab("https://www.linkedin.com/in/piyushchatterjee/")
              }
              icon={<GrLinkedin />}
            />
          </div>
        </div>
        <div className="right-col">
          <img src={pcatjee} alt="Piyush Chatterjee" />

          {/* <div className="highlights horizontal">
            <div className="icon">
              <BsAwardFill />
            </div>
            <div className="text">Best Design Award</div>
          </div> */}

          {/* <div className="highlights verticle">
            <div className="icon">
              <FaUser />
            </div>
            <div className="text">
              <span>4k+</span>
              Happy Customers
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default IntroContent;
